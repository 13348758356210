@font-face {
  font-family: 'Myriad Pro';
  src: url('/assets/fonts/MyriadPro-Regular.eot');
  src: url('/assets/fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/MyriadPro-Regular.woff2') format('woff2'),
    url('/assets/fonts/MyriadPro-Regular.woff') format('woff'),
    url('/assets/fonts/MyriadPro-Regular.ttf') format('truetype'),
    url('/assets/fonts/MyriadPro-Regular.svg#MyriadPro-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-medium';
  src: url('/assets/fonts/Gotham-Bold.eot');
  src: url('/assets/fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Gotham-Bold.woff2') format('woff2'),
    url('/assets/fonts/Gotham-Bold.woff') format('woff'),
    url('/assets/fonts/Gotham-Bold.ttf') format('truetype'),
    url('/assets/fonts/Gotham-Bold.svg#MyriadPro-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body:has(.email-landing-container) {
  background: url("../assets/landingPage/landing-bg.jpg") #001033 !important;
  background-repeat: no-repeat;
  background-size: cover !important;
  font-family: 'Myriad Pro', 'sens-serif';
  overflow: hidden;

  .email-landing-img {
    // height: 250px;
    object-fit: contain;

    @media screen and (max-width: 768px) {
      height: auto;
      padding: 0 1rem;
      width: 75%;
    }
  }

  // .input-upcoming-subscription {
  //   @media screen and (max-width: 768px) {
  //     margin-bottom: 1rem;
  //   }
  // }
  .btn-subscription-box {
    @media screen and (max-width: 767px) {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 4px;

      .btn-subscription {
        font-size: 16px !important;
      }
    }

    .btn {
      border-radius: 0.5rem;
      color: var(--whiteColor);
      fill: var(--whiteColor);
      font-size: 1rem;
      line-height: 27px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.375rem 0.75rem;
    }

    .btn-subscription {
      // background-color: #C728B7;
      background-color: #00e0ea;

      font-size: 1.2rem;
      // color: white;
      color: black;
      height: 100%;
      border-radius: 50em;
      padding: 0.6rem 2.2rem;
      font-weight: 600;

      // @media screen and (max-width: 768px) {
      //   font-size: 0.875rem;
      // }
      // box-shadow: 0px 0px 37.671234130859375px 0px #C728B799;
      // box-shadow: 8px 8px 16px rgba(0,0,0, 0.2), inset -8px -8px 12px rgba(0,0,0, 0.2), inset 8px 8px 12px rgba(255, 255, 255, 0.4);
      &:hover {
        opacity: 0.8;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .pe-md-12 {
      padding-right: 12rem;
    }
  }

  a {
    color: #C728B7;
    text-decoration: none;
  }

  .mb-80 {
    margin-bottom: 5rem;
  }

  .landing-banner-card {
    max-width:70%;
    @media screen and (max-width: 767.99px) {
      max-width: 100%;
    }
  }

  .landing-banner-girl {
    height: 100vh;
    width: auto;
    max-width: unset;
    opacity: 0.9;
    position: relative;
    z-index: -1;

    @media screen and (max-width: 991px) {
      position: fixed;
      top: 0;
      left: 0;
      opacity: 1;
      object-fit: contain;
      width: 100%;
      height: auto;
    }
  }

  @media screen and (max-width: 991px) {
    .mobile-height {
      height: 100dvh !important;
    }
  }

  // .landing-banner-girl-col {
  //   @media screen {

  //   }
  // }
  .start-landing-container {

    video,
    img.mobile-logo {
      width: 100%;
      height: 100%;
      mix-blend-mode: screen;

      @media screen and (max-width: 991px) {
        width: 80%;
      }

      @media screen and (max-width: 767px) {
        position: fixed;
        left: 0;
        top: 0;
        height: auto;
        width: 200px;
      }
    }
  }

  .email-landing-cross-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    // &:hover {
    // opacity: .8;
    // }
  }

  .thank-you-message {
    text-align: center;
    color: white;
  }

  .form-section {
    .form-group {
      max-width: 450px;
    }

    .form-control,
    .form-select {
      width: 100%;
      height: 2.5rem;
      background-color: #153a81;
      border: 1px solid #153a81;
      border-radius: 0.5rem !important;
      -webkit-border-radius: 0.5rem !important;
      -moz-border-radius: 0.5rem !important;
      -ms-border-radius: 0.5rem !important;
      -o-border-radius: 0.5rem !important;
      color: #ffffff;
      z-index: 3;
    }

    .form-control {
      font-weight: 500;
      border-radius: 50em !important;
      font-size: 0.875rem;
      min-height: 48px;

      @media screen and (min-width: 768px) {
        font-size: 18px;
      }

      &::placeholder {
        color: #6396c0;
        font-size: 0.875rem;

        @media screen and (min-width: 768px) {
          font-size: 18px;
        }
      }
    }

    .form-label {
      font-size: 18px;
      display: flex;
      align-items: center;
      margin-bottom: 0.25rem;
    }

    .hyperlink {
      color: #E8E9EB;
    }
  }
}



.input-upcoming-subscription {
  // @media screen and (min-width: 768px) {
  //   min-height: 70px;
  // }
  border-radius: 50em !important;
}

// .skeleton {
// position: relative;
// overflow: hidden;
// &::before {
//   content: '';
//   background-color: transparent;
//   background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 136, 235, 0.5), rgba(255, 255, 255, 0));
//   background-size: 40px 500%;
//   background-repeat: no-repeat;
//   background-position: left -40px top 0;
//   -webkit-animation: shine 2s ease infinite;
//   animation: shine 2s ease infinite;
//   position: absolute;
//   inset: 0;
//   height: 350%;
//   transform: rotateZ(45deg) translate(-20%, -25%);
// }
// }
.fade-in {
  animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {

  0%,
  100% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes shine {
  100% {
    background-position: right -40px top 0;
  }
}

// img {
//   filter: blur(40px)
// }

.img-1 {
  position: absolute;
  top: 0;
  right: 0;
}

.img-3 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.img-4 {
  position: absolute;
  bottom: 0;
  right: 500px;
}
.img-2 {
  position: absolute;
  right: 0;
  top: 25%;
}

.play-now-btn {
  background-color: #00e1eb;
  border: none;
  color: #000000;
  font-size: 18px;
  padding: 22px 50px;
  // font-weight: bold;
  border-radius: 50px;
  font-family: 'Gotham-medium';
  text-transform: uppercase;
}